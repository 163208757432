import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutNew from "../../components/layout-new";
import Seo from "../../components/seo"

const Page831bQualify = () => {
  const data = useStaticQuery(graphql`
    query Page831bQualifyQuery {
      prismicQualifyFormPage {
        data {
          page_title
          page_short_description
          jotform_url1
        }
      }
    }
  `)

  const doc = data.prismicQualifyFormPage

  return ( 
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
      />

      <div className="pt-14 xl:pt-20">
        <iframe
          title={doc.data.page_title}
          src={doc.data.jotform_url1}
          width="100%"
          height="700"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </LayoutNew>
  )
}

export default Page831bQualify
